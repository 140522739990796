<template>
  <v-app>
    <!--<v-app-bar app color="primary" dark> </v-app-bar>-->

    <!-- <v-main>
      <div class="d-flex flex-column align-center">
        <h1 class="mt-8">ATS L&D Support Request</h1>
        <img class="logo mb-12" src="./assets/amazon_smile.svg" />
        <p class="text-center mb-6">
          Use this form to request field learning support for Amazon
          Transportation Services (ATS)<br />Please select the
          <span class="font-weight-bold">region</span> of the impacted learning
          population and the <span class="font-weight-bold">type</span> of
          learning support they need. You will be redirected to a specific form
        </p>
        <p class="text-center mb-8">
          Note that you must log in into Asana to submit the request
        </p>
        <div class="world-map-wrap">
          <WorldMap />
        </div>
      </div>
    </v-main> -->
  </v-app>
</template>

<script>
// import WorldMap from "./components/WorldMap.vue";

window.location.replace("https://learning.euats.amazon.dev/team");

export default {
  name: "STSCLDIntakeForm",

  // components: {
  //   WorldMap,
  // },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "Amazon Ember", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  text-decoration: none;
}

h1 {
  font-size: 4rem;
}

.logo {
  width: 20rem;
}

@font-face {
  font-family: "Amazon Ember";
  src: url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-BoldItalic.woff2)
      format("woff2"),
    url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-BoldItalic.woff)
      format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Amazon Ember";
  src: url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Light.woff2)
      format("woff2"),
    url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Light.woff)
      format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Amazon Ember";
  src: url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-LightItalic.woff2)
      format("woff2"),
    url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-LightItalic.woff)
      format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Amazon Ember";
  src: url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Bold.woff2)
      format("woff2"),
    url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Bold.woff)
      format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Amazon Ember";
  src: url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Heavy.woff2)
      format("woff2"),
    url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Heavy.woff)
      format("woff");
  font-weight: 900;
  font-style: bold;
}

@font-face {
  font-family: "Amazon Ember";
  src: url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Regular.woff2)
      format("woff2"),
    url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Regular.woff)
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Amazon Ember";
  src: url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Italic.woff2)
      format("woff2"),
    url(https://m.media-amazon.com/images/G/01/chuxuanc/polarwiki/fonts/AmazonEmber-Italic.woff)
      format("woff");
  font-weight: 400;
  font-style: italic;
}
.world-map-wrap {
  width: 35%;
  padding-top: 2rem;
}
</style>
